
import { mapGetters } from 'vuex'

export default {
  data: () => ({

  }),

  computed: {
    ...mapGetters({
      menus: 'menus'
    })
  },

  watch: {
    $route (to, from) {
      this.$nextTick(() => {
        this.$refs['header-navbar-menu'].querySelectorAll('.navbar-dropdown').forEach((dropdown) => {
          dropdown.classList.add('is-hidden')
        })
      })
    }
  },

  methods: {
    generateLink (slug) {
      if (slug.includes('/')) {
        const slugs = slug.split('/')

        return this.localePath({
          name: 'slug-params',
          params: {
            slug: slugs[0],
            params: slugs[1]
          }
        })
      } else {
        return this.localePath({ name: 'slug', params: { slug } })
      }
    }
  }
}
