
import { mapGetters } from 'vuex'

export default {
  props: {
    address: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters({
      country: 'country'
    })
  },

  methods: {
    remove (address) {
      this.$axios.$delete(`${this.$i18n.locale}/${this.country.iso_code}/user/addresses/${address.id}`)
        .then((res) => {
          this.$emit('removed', res)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
