
import Forgot from '@/components/auth/Forgot'
import Login from '@/components/auth/Login'
import Register from '@/components/auth/Register'

export default {
  components: {
    Forgot,
    Login,
    Register
  },

  data: () => ({
    modal: {
      type: 'login'
    }
  }),

  methods: {
    setModalType (type) {
      this.modal.type = type
    }
  }
}
