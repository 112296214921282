
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      submitted: undefined,
      remaining: 60000, // 60 seconds
      form: {
        email: {
          value: '',
          error: ''
        },
        name: {
          value: '',
          error: ''
        },
        subject: {
          value: '',
          error: ''
        },
        body: {
          value: '',
          error: ''
        }
      },
      error: '',
      button: {
        loading: false
      }
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      email: 'modal/email',
      support: 'support/support'
    }),

    locale () {
      return this.$i18n.locales.find(locale => locale.code === this.$i18n.locale)
    }
  },

  created () {
    this.submitted = false
  },

  methods: {
    validateName () {
      if (this.form.name.value.length) {
        this.form.name.error = ''
      } else {
        this.form.name.error = this.$t('support.email.name.errors.required')
      }
    },

    validateEmail () {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.form.email.value)) {
        this.form.email.error = ''
      } else {
        this.form.email.error = this.$t('support.email.email.errors.invalid')
      }
    },

    validateSubject () {
      if (this.form.subject.value.length) {
        this.form.subject.error = ''
      } else {
        this.form.subject.error = this.$t('support.email.subject.errors.required')
      }
    },

    validateBody () {
      if (this.form.body.value.length) {
        this.form.body.error = ''
      } else {
        this.form.body.error = this.$t('support.email.body.errors.required')
      }
    },

    submit () {
      this.validateName()
      this.validateEmail()
      this.validateSubject()
      this.validateBody()

      if (this.form.email.error.length ||
        this.form.name.error.length ||
        this.form.subject.error.length ||
        this.form.body.error.length ||
        !this.email.tabs.includes(this.email.tab)
      ) {
        this.button.loading = false

        return
      }

      this.button.loading = true

      const data = {
        name: this.form.name.value,
        email: this.form.email.value,
        subject: this.form.subject.value,
        body: this.form.body.value
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/email/${this.email.tab}`, data)
        .then((res) => {
          // console.log(res)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.button.loading = false
          this.submitted = true
        })
    }
  }
}
