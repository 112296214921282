
import { mapGetters } from 'vuex'

import Banner from '@/components/index/Banner'

export default {
  components: {
    Banner
  },

  props: {
    banner: {
      required: true,
      default () {
        return {}
      },
      type: Object
    },
    currency: {
      required: true,
      default () {
        return {}
      },
      type: Object
    }
  },

  data () {
    return {

    }
  },

  computed: {
    link () {
      return {
        name: 'slug',
        params: {
          slug: this.banner.bannerable.page.slug
        }
      }
    },

    ...mapGetters({

    })
  },

  methods: {

  }
}
