
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [
    clickaway
  ],

  data: () => ({
    mouseoverCategory: false,
    mouseoverChildCategoryVar: false,
    isMounted: false
  }),

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      menu: 'catalog/mobile',
      compare: 'compare/compare',
      categories: 'categories',
      menus: 'menus',
      notification: 'notification'
    }),

    quantity () {
      return {
        cart: this.cart.items.reduce((quantity, item) => quantity + item.quantity, 0),
        compare: this.compare.items.length
      }
    }
  },

  watch: {
    'menu.active': {
      handler (val) {
        const html = document.documentElement

        if (val) {
          html.style.overflow = 'hidden'
        } else {
          html.style.overflow = null
        }
      },
      immediate: false,
      deep: true
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isMounted = true
    })
  },

  methods: {
    generateLink (slug) {
      if (slug.includes('/')) {
        const slugs = slug.split('/')

        return this.localePath({
          name: 'slug-params',
          params: {
            slug: slugs[0],
            params: slugs[1]
          }
        })
      } else {
        return this.localePath({ name: 'slug', params: { slug } })
      }
    },

    open () {
      this.$store.dispatch('catalog/setMobileMenuActive', !this.menu.active)
    },

    away () {
      this.$store.dispatch('catalog/setMobileMenuActive', false)
    },

    showMobileSubcategories (event) {
      event.target.parentElement.getElementsByClassName('mobile-subcategories')[0].classList.add('mobile-subcategories-active')
    },

    hideMobileSubcategories (event) {
      event.target.closest('.mobile-subcategories').classList.remove('mobile-subcategories-active')
    },

    showMobileSubsubcategories (event) {
      event.target.parentElement.getElementsByClassName('mobile-subsubcategories')[0].classList.add('mobile-subsubcategories-active')
    },

    hideMobileSubsubcategories (event) {
      event.target.closest('.mobile-subsubcategories').classList.remove('mobile-subsubcategories-active')
    }
  }
}
