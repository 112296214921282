
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      info: 'modal/info'
    })
  },

  watch: {
    $route (to, from) {
      this.$nextTick(() => this.$emit('toggle'))
    }
  }
}
