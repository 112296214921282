
import { mapGetters } from 'vuex'

export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart'
    })
  }
}
