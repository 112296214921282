
export default {
  props: {
    product: {
      required: true,
      type: Object
    },
    images: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      isMounted: false,
      galleryModalActive: false,
      arrowButton: false,
      options: {
        // simulateTouch: false,
        spaceBetween: 16,
        // loop: true,
        slidesPerView: 1,
        // loopedSlides: 5, // looped slides should be the same
        effect: 'slide',
        // fadeEffect: {
        //   crossFade: true
        // },
        on: {
          slideChange: this.topSlideChange
        }
        // breakpoints: {
        //   768: {
        //     effect: 'slide'
        //   }
        // }
      },
      thumbsOptions: {
        spaceBetween: 0,
        slidesPerView: 4
      }
    }
  },

  beforeMount () {
    window.addEventListener('keydown', this.keyDownHandler, { passive: true })
  },

  mounted () {
    this.setButtons()
    this.arrowButton = true
    this.$nextTick(() => {
      this.isMounted = true
    })
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.keyDownHandler)
  },

  methods: {
    setButtons () {
      const color = this.convertHexToRGBA('#ffffff', 0.25)

      this.$refs.swiperPrev.style.background = `${color} ${this.swiperPrevBackgroundImage('#000000')} no-repeat center`

      this.$refs.swiperNext.style.background = `${color} ${this.swiperNextBackgroundImage('#000000')} no-repeat center`
    },

    swiperPrevBackgroundImage (color) {
      return `url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%20height%3D%2234%22%20width%3D%2221%22%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'${color.replace('#', '%23')}'%2F%3E%3C%2Fsvg%3E")`
    },

    swiperNextBackgroundImage (color) {
      return `url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%20height%3D%2234%22%20width%3D%2221%22%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'${color.replace('#', '%23')}'%2F%3E%3C%2Fsvg%3E")`
    },

    convertHexToRGBA (hexCode, opacity) {
      let hex = hexCode.replace('#', '')

      if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
      }

      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)

      return `rgba(${r},${g},${b},${opacity})`
    },

    slideTo (index) {
      // if swiper reaches the end of displayed items, goToNext redirects swiper to the start
      if (index >= this.swiper.slides.length) {
        index = 0
      }

      // if swiper wants to go before the first item, then forward swiper to the last item
      if (index <= -1) {
        index = this.swiper.slides.length - 1
      }

      this.swiper.slideTo(index, 1000, false)
      this.thumbsSwiper.slideTo(index, 1000, false)

      this.$refs.thumbsSwiper.querySelectorAll('div')
        .forEach(slide => slide.classList.remove('swiper-slide-active'))

      this.$refs.thumbsSwiper.querySelector(`:nth-child(${index + 1})`)
        .classList.add('swiper-slide-active')
    },

    topSlideChange () {
      this.thumbsSwiper.slideTo(this.swiper.realIndex, 1000, false)

      this.toggleThumb(this.swiper.realIndex)
    },

    toggleThumb (index) {
      this.$refs.thumbsSwiper.querySelectorAll('div')
        .forEach(slide => slide.classList.remove('swiper-slide-active'))

      this.$refs.thumbsSwiper.querySelector(`:nth-child(${index + 1})`)
        .classList.add('swiper-slide-active')
    },

    keyDownHandler (e) {
      if (e.key === 'ArrowLeft') {
        this.slideTo(this.swiper.snapIndex - 1)
      } else if (e.key === 'ArrowRight') {
        this.slideTo(this.swiper.snapIndex + 1)
      }
    }
  }
}
