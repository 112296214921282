
import { mapGetters } from 'vuex'

import Cartable from '@/components/Cartable'
import CartFooter from '@/components/CartFooter'

export default {
  components: {
    Cartable,
    CartFooter
  },

  props: {
    type: {
      type: String,
      default () {
        return 'default'
      }
    }
  },

  data () {
    return {
      height: {
        header: 0,
        item: 0,
        footer: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      currency: 'currency',
      settings: 'settings'
    }),

    cartableHeight () {
      return this.height.item
    },

    cartablesHeight () {
      if (this.height.footer === 0 || this.height.item === 0) {
        return
      }

      return Math.floor((this.$refs.cartModal.clientHeight - this.$refs.cartModalHeader.clientHeight - this.height.footer) / this.height.item) * this.height.item
    },

    cartablesTotalHeight () {
      if (this.height.footer === 0 || this.height.item === 0) {
        return
      }

      return this.cart.items.reduce((total, item) => {
        return total + (this.$refs[`cartable[${item.id}]`] ? this.$refs[`cartable[${item.id}]`][0].$el.clientHeight : this.cartableHeight)
      }, 0)
    }
  },

  methods: {
    setFooterHeight (value) {
      this.height.footer = value
    },

    setCartableHeight (value) {
      this.height.item = value
    }
  }
}
